<template>
  <div>
    <div class="form-group">
      <label>Encabezado:</label>
      <vue-editor
        id="editor_encabezado"
        class="editor1"
        useCustomImageHandler
        @image-added="handleImageAdded"
        v-model="item.pln_encabezado"
      ></vue-editor>
    </div>
    <div class="form-group">
      <label>Contenido:</label>
      <vue-editor
        id="editor_contenido"
        class="editor2"
        useCustomImageHandler
        @image-added="handleImageAdded"
        v-model="item.pln_contenido"
      ></vue-editor>
    </div>
    <div class="form-group">
      <label>Pie de página:</label>
      <vue-editor
        id="editor_piepagina"
        class="editor2"
        useCustomImageHandler
        @image-added="handleImageAdded"
        v-model="item.pln_piepagina"
      ></vue-editor>
    </div>
    <div class="form-group">
      <label>Marca de agua:</label>
      <vue-editor
        id="editor_marcaagua"
        ref="editor_marcaagua"
        class="editor2"
        useCustomImageHandler
        @image-added="handleImageAdded"
        v-model="item.pln_marcaagua"
        :editorToolbar="customToolbar"
      ></vue-editor>
    </div>
  </div>
</template>

<script>
//import toastr from 'toastr';
import { VueEditor, Quill } from "vue2-editor";

export default {
  props: ['item'],
  components: {
    VueEditor,
  },
  data() {
    return {
      customToolbar: [["image"]]
    };
  },
  created: function () {

  },
  methods: {
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      var data = new FormData();
      data.append("file", file);
      data.append("name", file.name);
      data.append("host", window.location.origin);
      data.append("cmp_id", this.$store.state.company);

      this.axios
        .post("/api/images", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          let url = result.data.url; // Get url from response
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
};
</script>
<style>
.warning {
  color: #ff0000;
}

.editor1 .ql-editor {
  min-height: 60px;
}
.editor2 .ql-editor {
  min-height: 100px;
}
.editor3 .ql-editor {
  min-height: 200px;
}
</style>
