<template>
  <div class="col-md-8 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>{{ title }}</h1>
        </div>
        <br>
        <form v-on:submit.prevent="saveItem()">
          <div class="form-group">
            <label>Código (*):</label>
            <input type="text" class="form-control" v-model="item.pln_cdgo" required autofocus>
          </div>
          <div class="form-group">
            <label>Nombre (*):</label>
            <input type="text" class="form-control" v-model="item.pln_nmbre" required>
          </div>
          <div class="form-group">
            <label>Fuente (*):</label>
            <select class="form-control" v-model="item.pln_fuente" required>
              <option v-for="i in fuentes" v-bind:value="i.value">
                {{ i.text }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Tamaño (*):</label>
            <select class="form-control" v-model="item.pln_tamano" required>
              <option v-for="i in tamanos" v-bind:value="i.value">
                {{ i.text }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label><b style="color:darkblue">Tipo de plantilla:</b></label>
            <div class="text-left">
              <div class="form-check">
                <input class="form-check-input" type="radio" id="tipo1" v-model="item.pln_tipo" value="1">
                <label class="form-check-label" for="tipo1">Encabezado, Pie de página, Marca de agua</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" id="tipo2" v-model="item.pln_tipo" value="2">
                <label class="form-check-label" for="tipo2">Imagen de fondo</label>
              </div>
            </div>
          </div>
          <tipo-1 v-if="item.pln_tipo==1" :item="item"></tipo-1>
          <tipo-2 v-if="item.pln_tipo==2" :item="item"></tipo-2>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row" align="center">
            <div class="col-4">
              <button type="submit" class="btn btn-primary" v-on:click="setClose(true)">{{ buttontext }}</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-success" v-on:click="setClose(false)">Guardar</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-secondary" v-on:click.prevent="cancel()">Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
//import toastr from 'toastr';
import { VueEditor, Quill } from "vue2-editor";
import Tipo1 from './Tipo1.vue';
import Tipo2 from './Tipo2.vue';

export default {
  components: {
    VueEditor,
    Tipo1, Tipo2
  },
  data() {
    return {
      add: true, //Create a new document
      close: true, // Close the page and return to list
      title: "",
      item: {},
      timestamp: 0,
      message: "",
      buttontext: "",
      fuentes:[
        { text: 'Arial', value: 'Arial' },  
        { text: 'Comic Sans MS', value: 'Comic Sans MS' },
        { text: 'Impact', value: 'Impact' },
        { text: 'serif', value: 'serif' },
        { text: 'sans-serif', value: 'sans-serif' },
        { text: 'Tahoma', value: 'Tahoma' },
        { text: 'Times New Roman', value: 'Times New Roman' },
        { text: 'Times', value: 'Times' },
        { text: 'Verdana', value: 'Verdana' }               
      ],
      tamanos: [
        { text: '10', value: '10' },
        { text: '11', value: '11' },
        { text: '12', value: '12' },
        { text: '13', value: '13' },
        { text: '14', value: '14' },
        { text: '15', value: '15' },
        { text: '16', value: '16' },
        { text: '17', value: '17' },
        { text: '18', value: '18' },
        { text: '19', value: '19' },
        { text: '20', value: '20' }
      ]
    };
  },
  computed: {
    company() {
      return this.$store.state.company;
    },
    user() {
      return this.$store.state.user;
    },
    cancelButtonVisibility() {
      if (this.addInd) {
        return "visibility:hidden";
      } else {
        return "visibility:visible";
      }
    },
  },
  created: function() {
    this.fetchTimeStamp();

    this.add = this.$route.params.add;
    if (this.add) {
      this.title = "Crear una Plantilla";
      this.buttontext = "Agregar";
    } else {
      this.title = "Editar una Plantilla";
      this.buttontext = "Actualizar";
      this.getItem(this.$route.params.id);
    }
  },
  methods: {
    setClose(close) {
      this.close = close;
    },
    // Revisar sección para detectar las imágenes insertadas 
    reviewSection(section, images) {
      var x = 0;
      var y = 0;

      if (!section) return;
      
      while (-1 < x &&  x < section.length){
        var imgPath = '<img src="./../../temp/' + this.$store.state.company + '/templates/images/';
        x = section.indexOf(imgPath, x);
        
        if (x > -1){
          y = section.indexOf('">', x);
          x = x + imgPath.length;
          var file = section.substring(x, y);
          images.push(file);            
        }
      }
    },
    saveItem() {
      const saveImages = () => {
        var images = [];

        this.reviewSection(this.item.pln_encabezado, images);
        this.reviewSection(this.item.pln_contenido, images);
        this.reviewSection(this.item.pln_piepagina, images);
        this.reviewSection(this.item.pln_marcaagua, images);

        if (images.length > 0){
          let uri = "/api/saveimages";
          this.axios
            .post(uri, { cmp_id: this.item.cmp_id, images: images, upload: true })
            .then((response) => {
              console.log(response);
              if (this.close){
                this.$router.replace({ name: "DisplayPlantilla" });
              }
              this.message = "Registro guardado";
            })
            .catch((err) => {
              console.log(err);
              this.message = "¡Error al guardar las imágenes! " + err;
            });
        }
      }

      if (this.add) {
        this.item.cmp_id = String(this.$store.state.company);
        let uri = "/plantillas/add";
        //alert(this.item);
        this.axios
          .post(uri, this.item)
          .then((response) => {
            console.log(response);
            saveImages();
            this.message = "Registro guardado";
          })
          .catch((err) => {
            console.log(err);
            this.message = "¡Error al crear el registro! " + err;
          });
      } else {
        let uri = "/plantillas/update/" + this.item._id;
        this.axios.post(uri, this.item).then((response) => {
          saveImages();
          this.message = "Registro guardado";
        });
      }
    },
    getItem(id) {
      const downloadImages = () => {
        var images = [];

        this.reviewSection(this.item.pln_encabezado, images);
        this.reviewSection(this.item.pln_contenido, images);
        this.reviewSection(this.item.pln_piepagina, images);
        this.reviewSection(this.item.pln_marcaagua, images);

        if (images.length > 0){
          let uri = "/api/saveimages";
          this.axios
            .post(uri, { cmp_id: this.item.cmp_id, images: images, upload: false })
            .then((response) => {
              console.log(response);
              this.message = "Imágenes descargadas";
            })
            .catch((err) => {
              console.log(err);
              this.message = "¡Error al descargar las imágenes! " + err;
            });
        }
      }

      let uri = "/plantillas/edit/" + id;
      this.axios.get(uri).then((response) => {
        this.item = response.data;
        downloadImages();
      });
    },
    cancel() {
      this.$router.replace({ name: "DisplayPlantilla" });
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      var data = new FormData();
      data.append("file", file);
      data.append("name", file.name);
      data.append("host", window.location.origin);
      data.append("cmp_id", this.$store.state.company);

      this.axios.post("/api/images", 
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }        
      )
        .then(result => {
          let url = result.data.url; // Get url from response
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
        })
        .catch(err => {
          console.log(err);
        });
    },
    fetchTimeStamp(){
      let uri = '/api/timestamp';
      this.axios.post(uri)
      .then((response) => {
        this.timestamp = response.data.timestamp;
      })
      .catch((err) => {
        alert('No se pudo crear el identificador temporal. Consulte con el administrador.');
      });
    }
  },
};
</script>
<style>
  .warning {
    color: #ff0000;
  }

  .editor1 .ql-editor { min-height:60px }
  .editor2 .ql-editor { min-height:100px }
  .editor3 .ql-editor { min-height:200px }

</style>
